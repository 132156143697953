@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@font-face {
  font-family: "FormaDJRUI";
  src: url("../public/fonts/FormaDJRUI.woff2") format("woff2"),
    url("../public/fonts/FormaDJRUI.woff") format("woff");
}
@font-face {
  font-family: "FormaDJRUI-Italic";
  src: url("../public//fonts/FormaDJRUI-Italic.woff2") format("woff2"),
    url("../public/fonts/FormaDJRUI-Italic.woff2") format("woff");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "FormaDJRUI";
}

/* to control textBox veneer in invoice form */
.css-irj8ol {
  min-width: 0% !important;
}

.swiper-button-prev {
  height: 100% !important;
  scale: 50% !important;
}
.swiper-button-next {
  height: 100% !important;
  scale: 50% !important;
}

.css-yg955a {
  background: linear-gradient(
    39.71deg,
    #3aacd3 9.61%,
    rgba(12, 62, 237, 0.97) 105.91%
  );
  box-shadow: 0px 4px 15px rgba(52, 169, 211, 0.44);
  border: 0 !important;
}

.css-abwok8 {
  background: linear-gradient(
    39.71deg,
    #3aacd3 9.61%,
    rgba(12, 62, 237, 0.97) 105.91%
  );
  box-shadow: 0px 4px 15px rgba(52, 169, 211, 0.44);
  border: 0 !important;
  fill: white;
  color: white;
}
.css-abwok8 svg {
  fill: white;
  color: white;
}

.button-style-1 {
  background: var(
    --new-gradient,
    linear-gradient(270deg, #4759f5 -8.16%, #40defa 118.09%)
  );
  box-shadow: 0px 4px 15px 0px rgba(51, 158, 197, 0.4);
  border-radius: 10px;
}

.button-style-1:disabled {
  background: #e0e0e0;
  box-shadow: none;
}

.no-scrollbar::-webkit-scrollbar {
  background-color: white;
  width: 0 !important;
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

@media only screen and (max-width: 500px) {
  .css-p4eamf {
    min-width: calc(3 * 14px) !important;
  }
  .css-h4q5eb {
    min-width: calc(3 * 14px) !important;
  }
}

@media only screen and (max-width: 800px) {
  .swiper-button-prev {
    height: 80% !important;
    scale: 50% !important;
  }
  .swiper-button-next {
    height: 80% !important;
    scale: 50% !important;
  }
}

#update-cookies-div {
  position: fixed;
  right: 50px;
  bottom: 2.5px;
  width: 100%;
  height: fit-content;
  text-align: right;
  z-index: 9000;
  font-size: 10px;
}

#update-cookies-div a {
  color: black !important;
}

@media only screen and (max-width: 1149px) {
  #update-cookies-div {
    right: 30px;
    top: 55px;
  }
}
